.wrapper {
  position: relative;
  /* pointer-events: none; */
}

.overMap {
  position: absolute;
  top: 1rem;
  right: 3rem;
  z-index: 99;
  display: flex;
  flex-direction: row-reverse;
  width: calc(100% - 2rem);
  padding: 1rem;
}

.centerAlignedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heightAdjuster {
  height: 25rem;
}

.disabledMap {
  cursor: none;
}

.instructions {
  /* width: 45%; */
  height: 65vh;
  overflow: scroll;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--themeWhite);
}

.instructionsText {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: var(--themeGreen);
}

.polygon {
  /* width: 80%; */
  height: 10rem;
  border: 0.5px solid #ccc;
  border-radius: 0.2rem;
}

button:hover {
  opacity: 0.8;
}

/* button:disabled {
  background-color: var(--greyText) !important;
  cursor: not-allowed;
} */

.googleMap {
  border: 0.5px solid #ccc;
  border-radius: 0.2rem;
  width: 100%;
  align-self: center;
  height: 66vh;
  display: flex;
  align-self: center;
}

.unit {
  font-size: 1rem;
  color: var(--themeMain) !important;
}

.highlightedBase {
  background-color: var(--themeMainLighter);
  border-radius: 0.8rem;
  border: 0.3px solid var(--themeMain);
}
.width-50 {
  width: 50% !important;
}

.onboardingContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  width: 100%;
}
.onboardingContainer > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: calc(5%);
}

.sectionText {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--themeWhite);
}

.onboardingContainer > input,
.onboardingContainer > select {
  width: 40%;
}

.containerDiv {
  height: 80vh;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  display: none;
  visibility: hidden;
  width: 15rem;
  background-color: black;
  color: #fff;
  left: 8rem;
  text-align: center;
  align-self: center;
  top: 3.4rem;
  border: 1px solid black;
  padding: 1rem;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 5%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: flex;
  visibility: visible;
}

.tooltip:focus .tooltiptext {
  display: none;
  visibility: hidden !important;
}

.detectLocation {
  /* //w-85 ml-10 df fdr alic */
  width: 80%;
  /* margin-left: 1rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchContainer {
  /* df alsc fdr mb-10 aic w-65 */
  display: flex;
  align-self: center;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.detectConatimer {
  width: 25%;
}

.inputSearch {
  width: calc(100% - 2rem);
}

@media only screen and (max-width: 768px) {
  .tooltip .tooltiptext {
    display: none;
  }
  .tooltip:hover .tooltiptext {
    display: none;
  }
  .inputSearch {
    width: calc(100% - 2rem);
  }
  .overMap {
    position: relative;
    right: 0;
  }

  .instructions {
    /* width: 45%; */
    padding: 0rem;
    height: auto;
    /* overflow: unset; */
  }

  .detectLocation {
    /* //w-85 ml-10 df fdr alic */
    width: 95%;
    justify-content: center;
  }
  .detectLocation > span {
    display: none;
  }
  .detectConatimer {
    width: 28%;
  }
  .searchContainer {
    width: 95%;
  }
  .onboardingContainer {
    flex-direction: column;
  }
  .onboardingContainer > div {
    width: 100%;
  }
  .containerDiv {
    flex-direction: column;
  }
  .leftDiv {
    width: 100%;
  }

  .rightDiv {
    width: 100% !important;
  }
  .instructionsText {
    font-size: 1rem;
  }

  .width-50 {
    width: calc(100% - 4rem) !important;
  }
}
