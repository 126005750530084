.profileContainer {
  /* df p-10 fdr fww */
  display: flex;
  padding: 2rem;
  margin: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.profileItem {
  width: 50%;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .profileConatiner {
    flex-direction: column;
    display: flex;
    /* padding: 1rem; */
    /* margin: 1rem; */
  }
  .profileConatiner > div {
    width: 100%;
  }
  .profileItem {
    margin-top: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
