/* Initial Theme Colors */
:root {
  --themeWhite: #fff;
  --primaryGreen: #01783f;
  --themeGreen: #2d654a;
  --themeYellow: #f6aa1e;
  --themeBlue: #2354e1;
  --themeDark: #000;
  --greyText: #8d8ba7;
  --greyTextColor: #333333;
  --greyBorder: #d4d2e3;
  --themeMildDark: #4d4d4d;
  --lightGreyText: #f0f2f0;
  --lightText: #3f3f4d;
  --light: #f6f6f6;
  --themeLightGreen: #f2fff8;
  --inputBackground: #f9f9ff;
  --border: #e2e2e2;
  --greyBackground: #e8e8e8;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.tdul {
  text-decoration: underline !important;
}

.lightFontWeight {
  font-weight: 400 !important;
}

.topSection {
  /* background-image: url("./assets/top-background.jpeg"); */
  /* https://imgs.mongabay.com/wp-content/uploads/sites/30/2021/08/24091204/Banner-RS-2048x890.jpg */
  /* https://imgs.mongabay.com/wp-content/uploads/sites/30/2021/08/24091210/Pic-1-RS.jpg */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-size: cover; */
  /* box-shadow: inset var(--themeMain) 0px 0px 30px 5px; */
}

.darkCard {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 0.6rem;
  backdrop-filter: blur(6px);
  z-index: 0;
}

.darkCard > .minSlogan {
  align-self: center;
  display: block;
}

body {
  background-color: var(--themeWhite);
  /* font-family: Helvetica Neue, Helvetica, Arial, sans-serif; */
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "DM Sans";
  /* background-image: url("https://imgs.mongabay.com/wp-content/uploads/sites/30/2021/08/24091204/Banner-RS-2048x890.jpg"); /* https://imgs.mongabay.com/wp-content/uploads/sites/30/2021/08/24091210/Pic-1-RS.jpg */
  /* background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
  /* display: flex; */
}

header {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 3rem; */
  /* -webkit-backdrop-filter: blur(14px); */
  /* backdrop-filter: blur(14px); */
  z-index: 1;
  /* background-color: var(--themeWhite); */
  /* border-bottom: 0.2px solid var(--greyText); */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.05); */
}

.widthAdjuster {
  width: 80%;
  align-self: center;
}

.headerContainer {
  display: flex;
  width: 90%;
  margin: 1.25rem 5%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
a {
  text-decoration: none !important;
}

.headerContainer > div > a > span {
  color: var(--themeGreen);
  font-size: 1rem;
  font-weight: 300;
}
.headerContainer > div > a > span:hover {
  cursor: pointer;
  border-bottom: 0.05rem solid var(--primaryGreen);
  transition: 0.5s;
}

.headerText {
  font-size: 2rem;
  /* color: var(--themeWhite); */
  font-weight: 500;
  display: inline;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    var(--themeMain) 40%,
    var(--textMain) 40% 100%
  );
}

.headerCta {
  background-color: var(--primaryGreen);
  border-radius: 1.875rem;
  padding: 1.125rem 1.5rem;
  border: 0;
  color: var(--themeWhite);
  font-size: 0.875rem;
  font-weight: 700;
}

.semiCircleContainer {
  /* position: relative; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sloganTextContainer {
  /* margin-left: -1rem; */
}

.arrowRight {
  font-weight: 900 !important;
  font-size: 1.4rem !important;
  margin-left: 1rem;
}

.headingLight {
  font-size: 3rem;
  font-weight: 200 !important;
  color: var(--themeGreen);
}
.headingBolder {
  font-size: 3rem;
  font-weight: 600;
  color: var(--themeGreen);
}

.headingBold {
  font-size: 2.8rem;
  font-weight: 500;
  color: var(--themeGreen);
}

.brandImg {
  height: 2rem;
  filter: grayscale(0);
  border-radius: 0.4rem;
  padding: 1rem;
}

.brandImg:hover {
  filter: grayscale(1);
}

.topHeading {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  color: var(--themeBlue) !important;
}

.topsubHeading {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--themeGreen);
}

.topsubHeading > .blueText {
  font-weight: 700 !important;
  font-size: 2.4rem !important;
}

.models > .topsubHeading {
  text-align: center;
  font-size: 2.2rem !important;
  background-color: var(--lightGreyText);
  border-radius: 0.4rem;
}

.highlightBackground {
  background-color: var(--themeYellow);
  border-radius: 0.4rem;
}
.topMidHeading {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--themeGreen);
}

.topCta {
  padding: 0.8rem 1.2rem;
  border-radius: 2rem;
  background-color: var(--themeYellow);
  border: 0;
}

.topCta:hover {
  cursor: pointer;
  opacity: 0.8;
}

.topCta > span {
  font-size: 1.2rem;
  color: var(--themeDark);
  font-weight: 400;
}

.journeyWrapper {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.journeyStep {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin-top: 1.2rem;
}

.journeyLine {
  width: 0.3rem;
  height: 2rem;
  border-radius: 0.1rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.journeyStepActive > .journeyLine {
  height: 4rem;
}

.journeyLineFiller {
  height: 0%;
  width: 100%;
  border-radius: 0.1rem;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.9);
}

.journeyRight {
  width: 45%;
}

.journeyRight > img {
  width: 100%;
  /* height: 18rem; */
  border-radius: 0.4rem;
  transition: 0.5s;
}

.journeyStepText {
  font-size: 1rem;
  margin-left: 1.2rem;
  font-weight: 300;
  transition: 0.5s;
  color: var(--themeWhite);
  opacity: 0.6;
}

.journeyStepActive > .journeyStepText {
  opacity: 1;
  font-size: 1.4rem;
  font-weight: 500;
}

.journeyLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.headerLink {
  color: var(--themeWhite);
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
}

.headerLink:hover {
  cursor: pointer;
}

.semiCircleWrapper {
  /* margin-top: -4rem; */
  /* right: 0%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
}

.textContainer {
  background-color: var(--themeWhite);
  width: 100%;
  display: flex;
  margin-top: -3rem;
}

.textContainer > div > span {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--themeGreen);
  text-align: center;
}

/* .semiCircle { */
/* top: 10%; */
/* height: 150px;
  width: 300px;
  border-radius: 250px 250px 0 0;
  border-top: 1.4rem solid var(--themeYellow);
  border-left: 1.4rem solid var(--themeYellow);
  border-right: 1.4rem solid var(--themeYellow);
} */

.semiCircle {
  height: 12rem;
  width: 24rem;
  border-radius: 24rem 24rem 0 0;
  border-top: 1.4rem solid var(--themeYellow);
  border-left: 1.4rem solid var(--themeYellow);
  border-right: 1.4rem solid var(--themeYellow);
}

.raysContainer {
  margin-right: -1rem;
}

.rays {
  width: 0.8rem;
  height: 1.8rem;
  background-color: var(--themeYellow);
  transform: rotate(-30deg);
  margin-bottom: 1.4rem;
}

.rotate45 {
  /* transform: ; */
  transform: translateX(1rem) rotate(-45deg);
}

.iconBolt {
  font-size: 4rem !important;
  padding: 1rem 0.6rem;
  margin-left: -1.4rem;
  border: 0.7rem solid var(--themeGreen);
}

.rotate90 {
  transform: rotate(-90deg);
}

.rotate135 {
  /* transform: ; */
  transform: translateX(1rem) rotate(-135deg);
}

.semiCircleLeft {
  transform: rotate(-90deg);
  height: 60px;
  width: 120px;
  border-radius: 160px 160px 0 0;
  background-color: var(--themeYellow);
}

.line {
  height: 1.2rem;
  width: 0.2rem;
  background-color: var(--themeGreen) !important;
}

.hLine {
  height: 0.2rem;
  width: 2rem;
  background-color: var(--themeGreen) !important;
}

.iconCircle {
  font-size: 4rem !important;
  color: var(--themeYellow);
}

.blueText {
  color: var(--themeBlue) !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
}

.circle {
  height: 8rem;
  width: 8rem;
  border-radius: 8rem;
  border: 0.2rem solid var(--themeGreen);
}

.getQuoteCta {
  background-color: var(--themeMain);
  border-radius: 0.3rem;
  padding: 0.4rem 2.2rem;
  color: var(--themeWhite);
  border: 0;
  font-size: 0.8rem;
  font-weight: 600;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  color: var(--themeGreen);
  width: 10rem;
}

.mainDiv {
  width: 100%;
  display: flex;
  /* height: 90vh; */
  justify-content: center;
}

.slogan {
  font-size: 3.2rem;
  font-weight: 200;
  text-shadow: 0.5px 0.5px var(--themeMain);
  color: var(--themeWhite);
  text-align: center;
  letter-spacing: 0.1rem;
}

.value {
  margin-top: -3rem;
}

.minSlogan {
  text-shadow: 0.5px 0.5px var(--themeMain);
  font-size: 2.6rem;
  font-weight: 100;
  color: var(--themeWhite);
  text-align: center;
}

.sloganContainer {
  display: flex;
  margin-top: 10rem;
  justify-content: space-between;
  flex-direction: column;
}

.headerImage {
  width: 10.375rem;
  height: 4.189rem;
  /* border-radius: 2.5rem; */
}

.footerImage {
  height: 2.6rem;
  padding: 0.4rem;
  border-radius: 0.2rem;
  background-color: var(--themeWhite);
}

.secondarySloganContainer {
  display: flex;
  margin-top: 6rem;
  margin-bottom: 2rem;
  justify-content: center;
  flex-direction: column;
}
.topSectionImage {
  height: 28rem;
  border-radius: 0.4rem;
}

.subSlogan {
  text-shadow: 0.5px 0.5px var(--themeMain);
  font-size: 1rem;
  font-weight: 500;
  color: var(--themeWhite);
  text-align: center;
  margin-top: 2rem;
  letter-spacing: 0.1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.m-0 {
  margin: 0 !important;
}

.cta {
  background-color: var(--themeWhite);
  border-radius: 0.3rem;
  border: 0.18rem solid var(--themeWhite);
  padding: 0.4rem 2.2rem;
  color: var(--textMain);
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.alsc {
  align-self: center;
}

.whiteBg {
  width: 100%;
  /* height: 12rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--themeWhite);
}

.blueBoldBg {
  width: 100%;
  /* height: 12rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--themeGreen);
}

.blueBg {
  width: 100%;
  /* height: 12rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--themeMain);
}

.imgContainer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 4rem;
}

.imageIcon {
  width: 6rem;
  height: 6rem;
}

.blueBg > .sloganContainer {
  margin-bottom: 4rem;
}

.imgContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 30%;
  margin-bottom: 2rem;
}

/* .paragraph {
  margin: 1rem;
  font-size: 1rem;
  color: var(--light);
  font-weight: 100;
  text-align: center;
} */

.youtubeVideo {
  height: 36rem;
  border-radius: 0.2rem;
  width: 100%;
}

.p-40 {
  padding: 4rem;
}

.p-50 {
  padding: 5rem;
}

.whiteBg > span {
  margin-top: 4rem;
  font-size: 2.4rem;
  font-weight: 200;
  color: var(--themeMain);
  text-align: center;
}

.imgContainer > div:hover {
  transform: scale(1.1);
  transition: transform 0.6s;
  cursor: pointer;
}

.productContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1rem;
}

/* footer > div {
  width: 80%;
  margin-left: 10%;
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer > div > span {
  font-size: 0.8rem;
  color: var(--themeWhite);
  text-align: center;
  align-self: center;
} */

.icon {
  color: var(--themeWhite);
  font-size: 2.8rem !important;
}

.productContainer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 2rem; */
  margin-top: 1rem;
  width: 22%;
  padding: 0.5rem;
  border-radius: 0.4rem;
  border: 0.5px dashed rgba(255, 255, 255, 0.4);
}

.proudctIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.proudctIconContainer > span {
  color: var(--themeWhite);
  font-size: 0.8rem;
  letter-spacing: 0.08rem;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 1rem;
}

.proudctBrandConatiner {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 60%;
}

.proudctBrandConatiner > span {
  color: var(--themeWhite);
  font-size: 1rem;
  letter-spacing: 0.08rem;
  font-weight: 300 !important;
  opacity: 0.8;
  line-height: 1.6rem;
}

/* .productContainer > div > span {
  color: var(--themeWhite);
  font-size: 1rem;
  letter-spacing: 0.08rem;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 1rem;
} */

.check-icon {
  color: #5aa36d;
  font-size: 1.4rem !important;
}

.listIcon {
  color: var(--themeWhite);
  font-size: 1.4rem !important;
}

.pointText {
  font-size: 1.1rem;
  color: var(--themeWhite);
  margin-left: 0.6rem;
  letter-spacing: 0.1rem;
  transition: 0.5s;
}

.pointsContainer {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 0.8rem;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
  width: 20%;
}

.stepsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.dark {
  color: var(--themeMain) !important;
}

.next {
  font-size: 3rem !important;
}

.quoteContainer {
  align-self: center !important;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

span {
  letter-spacing: 0.05rem;
  font-weight: 400;
}

.mt-0 {
  margin-top: 0 !important;
}

.tabContainer {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pointsWrapper {
  margin-bottom: 4rem;
}

.bottomSection > .sloganContainer {
  margin-top: 4rem;
}
.bottomSection > .sloganContainer > .minSlogan {
  margin-top: 4rem;
}

.financePartners {
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.financePartners > div {
  margin: 2rem;
}

.financePartners > div > img {
  height: 2.8rem;
  filter: grayscale(1);
  border-radius: 0.4rem;
  background-color: #fff;
  padding: 1rem;
}

.financePartners > div > img:hover {
  filter: grayscale(0);
}

.tab {
  border-bottom: 0.2rem solid #fff;
  opacity: 0.4;
  width: 30%;
  padding: 0.4rem;
  cursor: pointer;
}

.tab:hover {
  opacity: 0.7 !important;
}

.active {
  opacity: 1 !important;
  transition: 0.5s;
}

.spanText {
  color: #fff;
  font-size: 1.3rem;
  letter-spacing: 0.05rem !important;
  font-weight: 400;
  line-height: 1.8rem;
  transition: 0.5s;
}

.textContainer > div:first-child {
  margin-left: 4rem;
}

.textContainer > div:nth-child(2) {
  /* margin-right: 2rem; */
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex !important;
}

.colorBlue {
  color: var(--themeBlue);
}

.colorGreen {
  color: var(--primaryGreen);
}

.colorYellow {
  color: var(--themeYellow);
}

.colorLessDark {
  color: var(--themeMildDark);
}

.colorLightText {
  color: var(--lightText);
}

.colorLightGreyText {
  color: var(--lightGreyText);
}

.colorGrexTextColor {
  color: var(--greyTextColor);
}

.colorGreyText {
  color: var(--greyText);
}

.colorDark {
  color: var(--themeDark);
}

.colorWhite {
  color: var(--themeWhite);
}

.bgWhite {
  background-color: var(--themeWhite);
}

.bgLightGreen {
  background-color: var(--themeLightGreen);
}

.bgGreen {
  background-color: var(--primaryGreen);
}

.bgGrey {
  background-color: var(--greyBackground) !important;
}

.bgDarkGrey {
  background-color: var(--greyText) !important;
}

.boldFont {
  font-weight: 700 !important;
}

.semiBoldFont {
  font-weight: 500 !important;
}

.lightBoldFont {
  font-weight: 300 !important;
}

.mediumBoldFont {
  font-weight: 400 !important;
}

.mediumFont {
  font-weight: 500 !important;
  font-size: 0.875rem;
  /* color: var(--primaryGreen); */
}

h1 {
  margin: 0;
  padding: 0;
  color: var(--primaryGreen);
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.2rem;
}

h1 > span {
  font-size: 3rem;
  font-weight: 800;
}

h2 {
  margin: 0;
  padding: 0;
  color: var(--primaryGreen);
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 2.5rem;
}
h3 {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
}
h4 {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
}

h5 {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 2.25rem;
  font-weight: 500;
}

h6 {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 700;
}

label {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: #666666;
}

input {
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--greyTextColor);
  border: 0.63px solid #66666659;
  outline-color: var(--greyText);
}

select {
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--greyTextColor);
  border: 0.63px solid #66666659;
  outline-color: var(--greyText);
}

.animationText::before {
  content: "ROOFTOP SOLAR";
  opacity: 1;
  animation: animate ease-in-out 8s;
  transition: opacity 1s;
}

@keyframes animate {
  0% {
    content: "GROWTH";
    opacity: 1;
  }
  25% {
    content: "GROWTH";
    opacity: 0.5;
  }
  50% {
    content: "GROWTH";
    opacity: 0;
  }
  51% {
    content: "ROOFTOP SOLAR";
    opacity: 0;
  }
  75% {
    content: "ROOFTOP SOLAR";
    opacity: 0.5;
  }
  100% {
    content: "ROOFTOP SOLAR";
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .secondaryCtaSmall {
    background-color: var(--themeWhite);
    border: 1px solid var(--greyBorder);
    border-radius: 2.5rem;
    padding: 0.6rem 0.8rem;
    color: var(--primaryGreen);
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
  }
  .headerContainer {
    /* margin-left: 0; */
    /* width: calc(80% - 1.6rem); */
    margin: 0;
    padding: 0.8rem !important;
    width: calc(100% - 1.6rem) !important;
  }
  header {
    padding: 0 !important;
    height: auto !important;
  }
  .widthAdjuster {
    width: 100% !important;
  }
  .mainDiv {
    height: auto !important;
  }
  .sloganContainer {
    margin-top: 10rem !important;
    margin-bottom: 6rem !important;
  }
  .secondarySloganContainer {
    margin-top: 6rem !important;
    margin-bottom: 2rem !important;
  }
  .slogan {
    font-size: 2rem !important;
  }
  .minSlogan {
    font-size: 2rem !important;
    font-weight: 500 !important;
  }
  .subSlogan {
    font-size: 0.8rem !important;
  }

  .cta {
    margin: 4rem 0;
    scale: 0.8 !important;
  }
  .headerCta {
    scale: 0.8 !important;
  }

  .customCta {
    border-radius: 2.5rem;
    border: 0;
    padding: 0.8rem 2rem;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
  }

  .imgContainer {
    flex-direction: column !important;
  }

  .imgContainer > div {
    width: 100% !important;
    align-self: center !important;
  }
  .stepsWrapper {
    flex-direction: column !important;
  }
  .stepsContainer {
    width: 100% !important;
  }
  .next {
    display: none !important;
  }
  .getQuoteCta {
    scale: 0.9 !important;
  }
  .tabContainer {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .spanText {
    font-size: 1rem;
    line-height: 1.3rem;
    width: 90%;
  }
  .pointText {
    line-height: 1rem;
    font-size: 0.9rem;
  }
  .listIcon {
    font-size: 1rem !important;
  }
  .tabContainer {
    align-items: normal;
  }
  .journeyWrapper {
    margin-top: 2rem;
    flex-direction: column;
  }
  .journeyStep {
    flex-direction: column;
    /* display: inline-block; */
    flex: 0 0 auto;
    width: 100%;
  }
  .journeyLine {
    display: none;
  }
  .journeyLeft {
    width: 94%;
  }
  .darkCard {
    padding: 2rem 0.4rem;
    margin: 0 1rem;
  }
  .journeyRight {
    display: none;
  }
  .journeyLine {
    height: 4rem;
  }
  .journeyStepActive > .journeyStepText {
    font-size: 1.2rem;
    font-weight: 500;
    opacity: 1;
  }
  .journeyStepText {
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 0;
  }

  .productContainer > div {
    width: 80%;
  }
  .proudctBrandConatiner > span {
    font-size: 0.8rem;
  }
  .proudctIconContainer > span {
    font-size: 0.7rem;
  }
  .bottomSection > .sloganContainer {
    margin-top: 4rem !important;
  }
  .topSection,
  /* #midSection, */
  .midSection {
    /* height: 100vh !important; */
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .tabContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tab {
    width: 90%;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  .pointsWrapper {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pointsContainer {
    width: 90%;
    align-self: center;
  }
  .tab > .icon {
    font-size: 1rem !important;
  }
  #whySolar {
    padding-bottom: 4rem;
  }
}

/* Constants */

.tar {
  text-align: right !important;
}

.tac {
  text-align: center;
}

.taj {
  text-align: justify;
}

.tal {
  text-align: left !important;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pb-05 {
  padding-bottom: 0.5rem;
}

.pt-02 {
  padding-top: 0.2rem;
}

.pb-02 {
  padding-bottom: 0.2rem;
}

.p-10 {
  padding: 1rem;
}

.p-20 {
  padding: 2rem;
}

.p-30 {
  padding: 3rem;
}

.m-05 {
  margin: 0.5rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.m-10 {
  margin: 1rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mb-10 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 0.5rem !important;
}

.mt-5 {
  margin-top: 0.5rem !important;
}

.mb-20 {
  margin-bottom: 2rem !important;
}

.mb-30 {
  margin-bottom: 3rem !important;
}

.mb-40 {
  margin-bottom: 4rem !important;
}

.mb-50 {
  margin-bottom: 5rem !important;
}

.mt-20 {
  margin-top: 2rem !important;
}

.mt-30 {
  margin-top: 3rem !important;
}

.mt-40 {
  margin-top: 4rem !important;
}
.mt-50 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 2rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-5 {
  margin-bottom: 0.5rem !important;
}

.ml-5 {
  margin-left: 0.5rem;
}

.ml-10 {
  margin-left: 1rem;
}
.ml-15 {
  margin-left: 1.5rem;
}

.ml-20 {
  margin-left: 2rem;
}

.ml-30 {
  margin-left: 3rem;
}

.mr-30 {
  margin-right: 3rem;
}

.ml-40 {
  margin-left: 4rem;
}

.mr-40 {
  margin-right: 4rem;
}

.mr-10 {
  margin-right: 1rem;
}

.df {
  display: flex;
}

.fdr {
  flex-direction: row;
}

.fdc {
  flex-direction: column;
}

.fdrr {
  flex-direction: row-reverse;
}

.fww {
  flex-wrap: wrap;
}

.fdc {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-1 {
  width: 1rem;
}

.h-75vh {
  height: 75vh;
}

.mh-75vh {
  min-height: 75vh;
}

.h-20 {
  height: 20%;
}

.w-95 {
  width: 95%;
}

.w-3 {
  width: 3%;
}

.w-8 {
  width: 8%;
}

.w-5 {
  width: 5%;
}
.w-90 {
  width: 90%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-14 {
  width: 14%;
}

.w-14-2 {
  width: 14.2%;
}

.w-15 {
  width: 15%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-55 {
  width: 55%;
}

.w-51 {
  width: 51%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-35 {
  width: 35%;
}

.asc {
  align-self: center;
}

.alsfs {
  align-self: flex-start;
}

.ase {
  align-self: end;
}

.alic {
  align-items: center;
}

.alifs {
  align-items: flex-start;
}

.alsfe {
  align-self: flex-end;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.jcse {
  justify-content: space-evenly;
}

.cp {
  cursor: pointer;
}

.cna {
  cursor: not-allowed;
}

.wsn {
  white-space: normal;
}

.topStrip {
  background-color: var(--primaryGreen);
}

.topStrip > p {
  padding: 0.75rem 5%;
}

.gradientText {
  background: linear-gradient(to right, #01783f, #f6aa1e);
  color: transparent !important;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.horizontalScroll {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.check {
  width: 0.75rem;
}

.leftWhiteCard {
  box-shadow: 0px 22px 24px 0px #c0c0c06f;
  border-radius: 1.25rem;
}

.rightGreenCard {
  border-radius: 1.25rem;
  background-color: var(--primaryGreen);
  box-shadow: 0px 6px 24px 0px var(--primaryGreen);
}

.smallWhiteCard {
  box-shadow: 0px 22px 24px 0px #c0c0c06f;
  border: 0.53px solid #d4d2e3;
  background: var(--themeWhite);
  border-radius: 1.25rem;
}

.customCta {
  border-radius: 2.5rem;
  border: 0;
  padding: 0.8rem 2.85rem;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: var(--primaryGreen);
  letter-spacing: 0.1rem;
}

.customCta > span {
  color: var(--themeWhite);
}

.customCta:disabled {
  background: var(--greyText);
}

.primaryCta {
  background-color: var(--primaryGreen);
  border-radius: 2.5rem;
  border: 0;
  padding: 1.5rem 2.25rem;
  color: var(--themeWhite);
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.secondaryCta {
  background-color: var(--themeWhite);
  border: 1px solid var(--greyBorder);
  border-radius: 2.5rem;
  padding: 1.5rem 2.25rem;
  color: var(--primaryGreen);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.secondaryCtaSmall {
  background-color: var(--themeWhite);
  border: 1px solid var(--greyBorder);
  border-radius: 2.5rem;
  padding: 0.8rem 1.2rem;
  color: var(--primaryGreen);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.topFooter {
  border-top: 1px solid var(--primaryGreen);
  margin: 2rem 5%;
  padding: 2rem 0;
}

.bottomFooter {
  border-top: 1px solid var(--themeYellow);
  padding: 2rem 5%;
}
