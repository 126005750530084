.centerAlignedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heightAdjuster {
  height: 25rem;
}

.disabledMap {
  cursor: none;
}

button:hover {
  opacity: 0.8;
}

/* button:disabled {
  background-color: var(--greyText) !important;
  cursor: not-allowed;
} */

.unit {
  font-size: 1rem;
  color: var(--themeMain) !important;
}

.highlightedBase {
  background-color: var(--themeMainLighter);
  border-radius: 0.8rem;
  border: 0.3px solid var(--themeMain);
}

@media only screen and (max-width: 768px) {
  .containerDiv {
    flex-direction: column;
  }

  .leftDiv {
    width: 100%;
  }

  .rightDiv {
    width: 100% !important;
  }
}
