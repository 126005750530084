.cardForm {
  margin: 2rem auto;
  width: 75%;
  background-color: var(--themeWhite);
  padding: 2rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 768px) {
  .cardForm {
    width: calc(100% - 4rem);
    padding: 1rem;
  }
}
