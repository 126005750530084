.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  /* background-color: rgb(0,0,0); Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 0.2rem 0.8rem 0.8rem 0.8rem;
  border: 0.05rem solid #888;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  width: calc(
    50% - 1.6rem
  ); /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  align-self: flex-end;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .modal-content {
    margin: 20% auto; /* 15% from the top and centered */
    width: calc(
      90% - 1.6rem
    ); /* Could be more or less, depending on screen size */
  }
}
