.colorGreenImp {
  color: var(--primaryGreen) !important;
}

.link {
  color: var(--primaryGreen);
  font-size: 0.8rem;
  font-weight: bolder;
  cursor: pointer;
  padding: 0.4rem;
  border: 0.05rem solid var(--primaryGreen);
  border-radius: 0.4rem;
}

.link:hover {
  opacity: 0.8;
}

#tableData {
  overflow: scroll;
}
