.topStrip {
  background-color: var(--primaryGreen);
}

.topStrip > p {
  padding: 0.75rem 5%;
}

.headerContainer {
  display: flex;
  width: 90%;
  margin: 1.25rem 5%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
a {
  text-decoration: none !important;
}

.headerContainer > div > a > span {
  color: var(--themeGreen);
  font-size: 1rem;
  font-weight: 300;
}
.headerContainer > div > a > span:hover {
  cursor: pointer;
  border-bottom: 0.05rem solid var(--primaryGreen);
  transition: 0.5s;
}

.headerText {
  font-size: 2rem;
  /* color: var(--themeWhite); */
  font-weight: 500;
  display: inline;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    var(--themeMain) 40%,
    var(--textMain) 40% 100%
  );
}

.headerImage {
  width: 10.375rem;
  height: 4.189rem;
  /* border-radius: 2.5rem; */
}

.headerCta {
  background-color: var(--primaryGreen);
  border-radius: 1.875rem;
  padding: 1.125rem 1.5rem;
  border: 0;
  color: var(--themeWhite);
  font-size: 0.875rem;
  font-weight: 700;
}

.menu,
.menu span {
  display: none;
}

.mobile-menu {
  display: none;
}

.menu-container {
  display: none;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@media all and (max-width: 768px) {
  .headerLinks {
    display: none !important;
  }

  .menu,
  .menu span {
    display: none;
  }

  .mobile-menu {
    display: block !important;
    /* position: absolute; */
    /* right: 0;
    top: 0; */
    /* padding: 0.8rem 1.4rem; */
  }
  .menu-container {
    display: flex;
    position: absolute;
    z-index: 2;
    right: -70%;
    top: 0;
    width: 70%;
    background-color: #fff;
    height: 100%;
    transform: translateX(-100%);
    border: 0.05rem solid var(--lightText);
    -webkit-transform: translateX(-100%);
  }

  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }

  .menu-item {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
  }

  .menu-item > a {
    color: var(--primaryGreen) !important;
    font-size: 1.2rem;
    font-weight: 400;
    opacity: 1;
    text-align: center;
    text-decoration: none;
  }

  .headerImage {
    width: 8rem;
    height: 3.6rem;
  }
  .headerContainer {
    /* margin-left: 0; */
    /* width: calc(80% - 1.6rem); */
    margin: 0;
    padding: 0.8rem !important;
    width: calc(100% - 1.6rem) !important;
  }
  header {
    padding: 0 !important;
    height: auto !important;
  }
}
