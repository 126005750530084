.bottomFooterLinks {
  /* df fdr alic */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
  width: 100%;
}

.bottomFooterLinks > a > span {
  font-size: 0.6rem;
  margin-left: 0;
}

.topFooter {
  border-top: 1px solid var(--primaryGreen);
  margin: 2rem 5%;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bottomFooter {
  border-top: 1px solid var(--themeYellow);
  padding: 2rem 5%;
  /* df fdr alic jcsb */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bottomFooterLinks {
  /* df fdr alic */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.footerDetails {
  /* df fdr alic jcsb mt-10 */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.footerItems {
  /* df fdr alic w-40 */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}

.footerItem-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}

.footerIcon {
  width: 1rem;
}

@media all and (max-width: 768px) {
  .topFooter {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .footerLinks {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    width: 100%;
  }

  .footerDetails {
    /* df fdr alic jcsb mt-10 */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .footerItems {
    /* df fdr alic w-40 */
    /* df fdr alic w-40 */
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
  }

  .footerItem-2 {
    /* df fdr alic w-40 */
    /* df fdr alic w-40 */
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
    width: 60%;
  }

  .bottomFooter {
    border-top: 1px solid var(--themeYellow);
    padding: 2rem 5%;
    /* df fdr alic jcsb */
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .socialIcons {
    width: 1.2rem !important;
  }

  .bottomFooterLinks {
    /* df fdr alic */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0rem;
    width: 100%;
  }

  .bottomFooterLinks > a {
    width: 30%;
    margin-bottom: 1rem;
  }
  .bottomFooterLinks > a > span {
    font-size: 0.6rem;
    margin-left: 0;
  }
}
