.reloadIcon {
  font-size: 1rem;
  color: var(--primaryGreen) !important;
}
.recalculate {
  width: 25%;
  align-self: center;
  background-color: var(--themeWhite) !important;
  border: 0.01rem solid var(--greyText) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkbox {
  border: 0.1rem solid var(--primaryGreen);
  width: 1rem;
  height: 1rem;
  border-radius: 0.1rem;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}

.checkbox > span {
  font-size: 0.7rem;
  position: absolute;
  align-self: center;
  /* left: 0.15rem;
  top: 0.15rem; */
}

.checkoutContainer {
  /* mt-20 mb-20 w-50 df fdc asc */
  margin: 4rem 0 0 0;
  padding: 1rem;
  width: calc(40% - 2rem);
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 0.4rem;
  border: 0.05rem solid var(--border);
}

.planDetailConatiner {
  padding: 0 1rem;
  display: flex;
  font-display: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.chartConatiner {
  width: 80%;
  padding: 0 10%;
}

.dashboardPlansContainer {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 10rem);
  background-color: var(--themeWhite);
}

.plansConatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.planItem {
  background-color: var(--themeWhite);
  border-radius: 0.4rem;
  border: 0.05rem solid var(--border);
  padding: 1rem 0;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.planItemSelected {
  border: 0.08rem solid var(--primaryGreen) !important;
}

.moduleConatiner {
  /* df fdr asc alic w-60 jcsb bgWhite mt-10 */
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.6rem;
  background-color: var(--themeWhite);
}

.modulesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dashboardHeadConatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dashboardHeadLeft {
  width: 60%;
}

.dashboardHeadRight {
  width: 25%;
}

.dashboardContainer {
  padding: 2rem 5rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 10rem);
  background-color: var(--primaryGreen);
}

.centerAlignedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heightAdjuster {
  height: 25rem;
}

.disabledMap {
  cursor: none;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 2rem; */
}

button:hover {
  opacity: 0.8;
}

/* button:disabled {
  background-color: var(--greyText) !important;
  cursor: not-allowed;
} */

.unit {
  font-size: 1rem;
  color: var(--themeMain) !important;
}

.highlightedBase {
  background-color: var(--themeMainLighter);
  border-radius: 0.8rem;
  border: 0.3px solid var(--themeMain);
}

.leftDiv {
  margin-right: 5%;
}

.selectedCard:hover {
  cursor: pointer;
  opacity: 0.9;
}

.selectedCard {
  /* padding: 1rem; */
  border: 0.08rem solid var(--primaryGreen);
  border-radius: 0.4rem;
  background-color: var(--lightGreyText);
}

.upDownIcons {
  transition: 1s !important;
}

.highlightedBg {
  background-color: var(--themeLightGreen);
  border-radius: 0.2rem;
  padding: 0.2rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlightedBg > span {
  color: var(--primaryGreen);
  font-size: 0.6rem;
  font-weight: 500;
}

.unselectedCard:hover {
  cursor: pointer;
  opacity: 0.9;
}

.unselectedCard {
  /* padding: 1rem; */
  border: 0.08rem solid var(--lightGreyText);
  border-radius: 0.4rem;
  background-color: var(--lightGreyText);
}

.radio {
  border-radius: 2rem;
  padding: 0.2rem;
  width: 0.6rem;
  display: flex;
  height: 0.6rem;
  border: 0.1rem solid var(--primaryGreen);
}

.selectedRadio {
  border-radius: 5rem;
  padding: 0.3rem;
  align-self: center;
  background-color: var(--primaryGreen);
}

.unSelectedRadio {
  border-radius: 5rem;
  padding: 0.3rem;
  align-self: center;
  background-color: var(--lightGreyText);
}

#tableData {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tableData td,
#tableData th {
  font-weight: 400;
  /* border: 1px solid #ccc; */
  padding: 0.8rem;
}

#tableData tr:nth-child(odd) {
  /* background-color: var(--light); */
}

#tableData tr:hover {
  background-color: var(--lightGreyColor);
}

#tableData th {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-align: left;
  background-color: var(--light);
  color: var(--themeBlue);
  font-size: 1.2rem;
}

#tableData tr {
  border-bottom: 0.05rem solid var(--greyText);
}

#tableData tr td:nth-child(1) {
  font-size: 1.2rem;
  font-weight: 500;
}

#tableData tr td {
  font-size: 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .recalculate {
    width: 90%;
  }
  .checkoutContainer {
    width: calc(100% - 2rem);
  }

  .plansConatiner {
    flex-direction: column;
  }
  .planItem {
    width: 100%;
    margin-bottom: 1rem;
  }
  .dashboardPlansContainer {
    padding: 1rem;
    width: calc(100% - 2rem);
  }
  .moduleConatiner {
    width: 90%;
  }

  .modulesWrapper > div {
    width: 90%;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }

  .modulesWrapper {
    flex-direction: column;
  }

  .dashboardContainer {
    padding: 1rem;
    width: calc(100% - 2rem);
  }

  .dashboardHeadConatiner {
    flex-direction: column;
  }

  .dashboardHeadLeft,
  .dashboardHeadRight {
    width: 100%;
    margin-bottom: 1rem;
  }

  .containingDiv {
    display: flex !important;
    flex-direction: column !important;
  }

  .leftDiv {
    width: 100%;
  }
  .rightDiv {
    margin-top: 0;
  }

  .unit {
    font-size: 0.8rem;
  }

  .businessDiv {
    margin-top: 2rem;
  }
}
