.container {
  background-image: url("../../assets//img/backdrop.png");
  background-repeat: no-repeat;
  width: 100%;
  padding: 1rem 0;
  background-size: cover;
}

.card {
  margin: 2rem auto;
  width: 35%;
  background-color: var(--themeWhite);
  padding: 2rem;
  border-radius: 1rem;
}

.colorGrexTextColor {
  color: var(--greyTextColor) !important;
}

.formLogo {
  width: 6.8rem;
  height: 2.8rem;
  margin: 0 auto;
  /* border-radius: 2.5rem; */
}

.fieldItem {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
}

.authBtn {
  margin-top: 1rem;
  width: 80%;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 1rem 0;
  }
  .card {
    margin: 1rem auto;
    width: calc(100% - 4rem);
    padding: 1rem;
  }
  .fieldItem {
    width: 100%;
  }
  .authBtn {
    width: 100%;
  }
}
