.tabConatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
}

.tabConatiner > .activeTab {
  border-bottom: 0.2rem solid var(--primaryGreen);
}

.tabConatiner > .activeTab > span {
  color: var(--primaryGreen);
}

.tabConatiner > div > span {
  font-size: 1rem;
  font-weight: 600;
}

.tabConatiner > div {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0 1rem;
  padding: 0.5rem;
  cursor: pointer;
}

.tabConatiner > .inActiveTab {
  border-bottom: 0.2rem solid var(--themeWhite);
}

.tabConatiner > .inActiveTab > span {
  color: var(--greyTextColor);
  opacity: 0.8;
}

.cna {
  cursor: not-allowed !important;
}

.tabConatiner::-webkit-scrollbar {
  display: none;
}

.tabConatiner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* . */
